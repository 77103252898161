import { Controller } from 'stimulus';

export default class extends Controller {
  // eslint-disable-next-line class-methods-use-this
  toggleVariants(event) {
    event.preventDefault();

    event.target.closest('.variant-container').classList.toggle('collapsed');
    // Muestro el boton (+ -) que no estaba visible
    event.target.closest('.expand-variants').querySelectorAll('.hidden')[0].classList.remove('hidden');
    // Y oculto el boton (+ -) que presione recién
    event.target.closest('a').classList.add('hidden');
  }
}
