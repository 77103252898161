/* global $ */
import { Controller } from 'stimulus';

export default class extends Controller {
  /* eslint-disable class-methods-use-this */
  handleClick(event) {
    /* eslint-enable class-methods-use-this */
    event.preventDefault();
    const target = event.currentTarget;

    if ($(target).attr('disabled')) {
      return;
    }

    $(target).attr('disabled', true);

    const { url } = target.dataset;
    const { successRedirectUrl } = target.dataset;
    const { method } = target.dataset;

    fetch(url, {
      method,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content'),
        Accept: 'text/javascript',
      },
    }).then(async (response) => {
      if (response.ok) {
        window.location.href = successRedirectUrl;
      } else {
        const body = await response.json();
        $(target).attr('disabled', false);
        /* eslint-disable no-alert */
        alert(body.errors);
        /* eslint-enable no-alert */
      }
    });
  }
}
