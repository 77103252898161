/* global $ */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'result'];

  connect() {
    $(this.formTarget).on('ajax:success', this.onSuccess);
  }

  disconnect() {
    $(this.formTarget).off('ajax:success', this.onSuccess);
  }

  onSuccess = (event, data, status, xhr) => {
    this.resultTarget.innerHTML = xhr.responseText;
    $(this.resultTarget).css('opacity', '1');
  };
}
